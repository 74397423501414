import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Sets html.normal-scroll on page navigation and then removes it.
 * With some CSS this can be used to disable smooth scroll on navigation.
 * https://github.com/vercel/next.js/issues/20125#issuecomment-757547865
 */
export const useNormalScrollRoutes = () => {
	const router = useRouter();

	useEffect(() => {
		const handleStart = () => {
			document.documentElement.classList.add('normal-scroll');
		};
		const handleComplete = () => {
			document.documentElement.classList.remove('normal-scroll');
		};

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleComplete);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleComplete);
		};
	}, [router.events]);
};
