import '@/colors';
import { Symplify } from '@/trackers/Symplify';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useNormalScrollRoutes } from '../hooks/useNormalScrollRoutes';
import '../icons';
import '../styles/fonts.scss';
import '../styles/globals.scss';

export default function App({ Component, pageProps }: AppProps) {
	useNormalScrollRoutes();

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />

				<link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" crossOrigin="use-credentials" />
				<link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" crossOrigin="use-credentials" />
				<link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" crossOrigin="use-credentials" />
				<link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" crossOrigin="use-credentials" />
				<link
					rel="apple-touch-icon"
					sizes="114x114"
					href="/favicon/apple-icon-114x114.png"
					crossOrigin="use-credentials"
				/>
				<link
					rel="apple-touch-icon"
					sizes="120x120"
					href="/favicon/apple-icon-120x120.png"
					crossOrigin="use-credentials"
				/>
				<link
					rel="apple-touch-icon"
					sizes="144x144"
					href="/favicon/apple-icon-144x144.png"
					crossOrigin="use-credentials"
				/>
				<link
					rel="apple-touch-icon"
					sizes="152x152"
					href="/favicon/apple-icon-152x152.png"
					crossOrigin="use-credentials"
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/favicon/apple-icon-180x180.png"
					crossOrigin="use-credentials"
				/>
				<link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/favicon/manifest.json" crossOrigin="use-credentials" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="msapplication-TileImage" content="/favicon/ms-icon-144x144.png" />
				<meta name="theme-color" content="#ffffff" />
				<meta content="yes" name="apple-mobile-web-app-capable" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
			</Head>
			<Component {...pageProps} />
			<Symplify />
		</>
	);
}
