import Script from 'next/script';

export const getSymplifyId = () => {
	const host = global.window?.location?.hostname;

	if (host === 'www.fortnox.se') {
		return '5620159';
	}

	return '5620134';
};

/**
 * Conditionally loads Symplify (sitegainer) script
 */
export const Symplify = ((): React.FC => {
	const symplifyId = getSymplifyId();

	if (!global.window) {
		return function SymplifyDisabled() {
			return null;
		};
	}

	const query = new URLSearchParams(location.search);
	// Disable Symplify when running inside Storyblok
	if (query.has('_storyblok')) {
		return function SymplifyDisabled() {
			return null;
		};
	}

	if (symplifyId === null) {
		return function SymplifyDisabled() {
			return null;
		};
	}

	return function Symplify() {
		return <Script type="text/javascript" src={`https://cdn-sitegainer.com/${symplifyId}/es6/index.bundle.js`} />;
	};
})();
